import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const totalPriceSlice = createSlice({
  name: "totalPrice",
  initialState: {
    basedPrice: 0,
    optionPrices: {}, // Изменение на объект для хранения цен для различных опций
    sumPrice: 0,
    optionsFromBasket: {},
    limitSquare: 0,
    idProduct: null,
    dimensions: { width: 0, height: 0 },
  },
  reducers: {
    setBasedPrice: (state, action) => {
      const { price, idProduct, dimension } = action.payload;
      if (price) {
        state.basedPrice = price;
      }
      console.log(price, idProduct, "TESTTTTTTTTTTTTTTTTTTTTTT");

      if (dimension) {
        // console.log(dimension, "TESTTTTTTTTTTTTTTTTTTTTTT");
        state.dimensions.width = dimension.width;
        state.dimensions.height = dimension.height;
      }
      state.sumPrice =
        state.basedPrice +
        Object.values(state.optionPrices).reduce(
          (acc, optionPrice) => acc + optionPrice,
          0
        );

      state.idProduct = idProduct; // Добавляем idProduct к состоянию
    },
    setLimitSquare: (state, action) => {
      const { width, height } = action.payload;
      state.limitSquare = (width * height) / 1000000;
    },

    resetTotalPriceOptions: (state) => {
      state.optionPrices = {};
    },
    resetTotalPriceDimensions: (state) => {
      state.dimensions = { width: 0, height: 0 };
    },

    updateTotalPrice: (state, action) => {
      const {
        price,
        name,
        type,
        typeOption = "test",
        nameOptionValue,
        checked = true,
        quantity = 1,
      } = action.payload;
      console.log(typeOption, "ПРОВЕРКА!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // Обновляем или добавляем цену для выбранной опции

      if (checked === false) {
        delete state.optionPrices[name];
        delete state.optionsFromBasket[name];
      } else {
        const totalPrice = price * quantity;
        if (type === "Процент") {
          // Если тип 'процент', вычислите цену как процент от basedPrice
          const percentagePrice = (state.basedPrice * totalPrice) / 100;
          state.optionPrices[name] = percentagePrice;
        } else {
          // В противном случае добавьте цену как фиксированное значение
          state.optionPrices[name] = totalPrice;
        }

        state.optionsFromBasket[name] = nameOptionValue;
      }
      // state.optionPrices[name] = nameModel;

      // Вычисляем сумму всех цен опций
      state.sumPrice =
        state.basedPrice +
        Object.values(state.optionPrices).reduce(
          (acc, optionPrice) => acc + optionPrice,
          0
        );
    },
  },
});

export const {
  updateTotalPrice,
  setBasedPrice,
  setLimitSquare,
  resetTotalPriceOptions,
  resetTotalPriceDimensions,
} = totalPriceSlice.actions;

export const selectBasedPrice = (state) => state.totalPrice.basedPrice;
export const selectOptionPrices = (state) => state.totalPrice.optionPrices;

export const selectSumPrice = createSelector(
  [selectBasedPrice, selectOptionPrices],
  (basedPrice, optionPrices) =>
    basedPrice +
    Object.values(optionPrices).reduce(
      (acc, optionPrice) => acc + optionPrice,
      0
    )
);

export default totalPriceSlice.reducer;
